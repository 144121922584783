// Fonts
@include fontCatamaran("../fonts/catamaran/");
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@include font($family: "FontAwesome", $src: "../fonts/fa/fa-regular-400");
@include font($family: "FontAwesomeSolid", $src: "../fonts/fa/fa-solid-900");
@include font($family: "FontAwesomeBrand", $src: "../fonts/fa/fa-brands-400");

// GLOBAL STYLES for all site
* {
	box-sizing: border-box;
	-webkit-overflow-scrolling: touch;

	&::selection {
		background-color: $accent;
		color: $white;
	}
}

body {
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
	
	font-family: $ctm, $akz, $def;
	font-weight: normal;
	font-size: 16px;
	line-height: 1.5;
	color: $color;
}
