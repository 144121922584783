// single
.single-page {
	position: relative;
	background-size: 100% auto;
    background-position: center top;
    background-repeat: no-repeat;
}

.single-page__bgtop,
.single-page__bgbottom {
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
}

.single-page__bgtop {
	width: 100%;
	min-height: auto; // 640px
	padding-top: 60px;
	padding-bottom: 20px;
}
.single-page__bgtop_pb {
	min-height: auto;

	.wrap-content {
		padding-bottom: 100px;

		@include tablets {
			padding-bottom: 0;
		}
	}
}
.single-page__bgtop_blog {
	@extend .single-page__bgtop;

	min-height: 240px;
	padding-bottom: 20px;

	@include tablets {
		min-height: auto;
		padding-bottom: 10px;
	}
}

.single-page__bgbottom {
	width: 100%;
	min-height: 160px;
}


// loading page
.page-loading {
	display: flex;
	width: 100%;
	padding-top: 100px + $headerDescH;
	padding-bottom: 100px;
	align-items: center;
	justify-content: center;
}

.page-loading__dot {
	display: block;
	width: 10px;
	height: 10px;
	margin: 0 5px;
	background-color: $color;
	border-radius: 50%;

	&:nth-of-type(1) { animation: colors 1s ease-in-out  0s infinite; }
	&:nth-of-type(2) { animation: colors 1s ease-in-out .2s infinite; }
	&:nth-of-type(3) { animation: colors 1s ease-in-out .4s infinite; }
}

.page-desc {
	max-width: 750px;
}


@keyframes colors {
	0%   {
		background-color: $darkGrey;
		transform: scale3d(0, 0, 0);
	}
	25%  {
		background-color: $color;
		transform: scale3d(1, 1, 1);
	}
	50%  {
		background-color: $link;
		transform: scale3d(2, 2, 2);
	}
	25%  {
		background-color: $color;
		transform: scale3d(1, 1, 1);
	}
	100% {
		background-color: $darkGrey;
		transform: scale3d(0, 0, 0);
	}
}
