// MEDIA QUERIES
// NOT mobile-first
@mixin x-large() {
	@media screen and (max-width: $x-large) {
		@content;
	}
}

@mixin large() {
	@media screen and (max-width: $large) {
		@content;
	}
}

@mixin medium() {
	@media screen and (max-width: $medium) {
		@content;
	}
}

@mixin tablets() {
	@media screen and (max-width: $tablets) {
		@content;
	}
}

@mixin l-phones() {
	@media screen and (max-width: $l-phones) {
		@content;
	}
}

@mixin phones() {
    @media screen and (max-width: $phones) {
		@content;
	}
}