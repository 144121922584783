// HELPER CLASSES
// classes with a single purpose to reduce the frequency of highly repetitive declarations

// display
.d_none  { display: none!important; }
.d_block { display: block; }
.d_flex  { display: flex; }

.d_desc {
	@include tablets { display: none!important; }
}

.jc_sbetween{
	justify-content: space-between;
}

.d_tablets {
	display: none;

	@include tablets { display: block; }
}

// flex
.flex_full {
	flex: 1 1 100%;
}

// margins
.mt_inner { margin-top: 40px; }

.mb_section { margin-bottom: 65px; }

.ml_auto { margin-left: auto; }

.mb50 {
	margin-bottom: 50px;
}

// no scroll
.no-scroll {
	height: 100%;
    overflow: hidden;
}

// VUE
.fade-enter-active, .fade-leave-active { transition: opacity .5s; }
.fade-enter, .fade-leave-to { opacity: 0; }