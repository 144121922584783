// transition
@mixin transition(
	$prop: all,
	$time: .2s,
	$func: ease-in-out,
	$delay: 0s
) {
	transition: $prop $time $func $delay;
}

// button
@mixin button(
	$h: $buttonStandH,
	$ff: $akz,
	$fz: 16px,
	$bradius: 37px,
	$color: $link,
	$hover: $accent
) {
	@include transition();

	display: inline-block;
	height: $h;
	line-height: $h - 4px;
	padding: 0 2em;
	font-family: $ff, $def;
	font-size: $fz;
	font-weight: 500;
	color: $color;
	text-align: center;
	text-decoration: none;
	background-color: transparent;
	box-shadow: none;
	border: 2px solid $color;
	border-radius: 37px;
	cursor: pointer;

	&:hover {
		color: $hover;
		border-color: $hover;
	}

	&:focus {
		outline: none;
	}

	&:disabled {
		color: #ccc;
		border-color: #ccc;
		cursor: not-allowed;
	}
}

// input
@mixin input(
	$w: 100%,
	$h: auto,
	$icolor: rgba($color, .5),	// text in input
	$bcolor: $white,			// background color
	$fcolor: rgba($link, .5),	// focus shadow color
	$pcolor: $placeholder		// placeholder text color
) {
	width: $w;
	height: $h;
	padding: 8px 20px 13px;
	font-family: $ctm;
	font-size: 18px;
	font-weight: 500;
	line-height: 1.5;
	color: $icolor;
	background-color: $bcolor;
	border: none;
	border-radius: 6px;

	&:focus {
		outline: none;
		box-shadow: 0 0 0 2px $fcolor inset;
	}
	
	&::-moz-placeholder          { color: $pcolor; @include transition(); }
	&::-webkit-input-placeholder { color: $pcolor; @include transition(); }
	&::-ms-input-placeholder     { color: $pcolor; @include transition(); }
	&::-ms-input-placeholder     { color: $pcolor; @include transition(); }
	&::placeholder               { color: $pcolor; @include transition(); } 
	
	&:focus::-moz-placeholder          { color: rgba($pcolor, 0); @include transition(); }
	&:focus::-webkit-input-placeholder { color: rgba($pcolor, 0); @include transition(); }
	&:focus::-ms-input-placeholder     { color: rgba($pcolor, 0); @include transition(); }
	&:focus::-ms-input-placeholder     { color: rgba($pcolor, 0); @include transition(); }
	&:focus::placeholder               { color: rgba($pcolor, 0); @include transition(); } 
}

// DECOR ELEMENTS
// underline with angle
@mixin underTab(
	$color: $link,
	$hover: rgba($link, .5)
	) {
		$c1: calc(50% - 9px);
		$c2: calc(50% + 10px);
		$wh: 13px;

	position: relative;

	&::before,
	&::after {
		//	@include transition();

		content: "";
		position: absolute;
		display: block;
		opacity: 1;
	}

	// line
	&::before {
		width: 100%;
		height: 2px;
		bottom: 0;
		background-image: linear-gradient(to right, $color 0%, $color $c1, transparent $c1, transparent $c1, transparent $c2, transparent $c2, $color $c2, $color 100%);
	}

	// angle
	&::after {
		width: $wh;
		height: $wh;
		left: 50%;
		bottom: 0;
		border-left: 2px solid $color;
		border-bottom: 2px solid $color;
		transform-origin: center;
    	transform: rotate(-45deg) translate(-70%, 0%);
	}

	&:hover {
		&::before,
		&::after {
			opacity: 1;
		}

		&::before {
			background-image: linear-gradient(to right, $hover 0%, $hover calc(50% - 10px), transparent calc(50% - 10px), transparent calc(50% - 10px), transparent calc(50% + 10px), transparent calc(50% + 10px), $hover calc(50% + 10px), $hover 100%); 
		}

		&::after {
			border-left: 2px solid $hover;
			border-bottom: 2px solid $hover;
		}
	}
}