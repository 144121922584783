.paragraph {
	display: block;
	margin: 0;
	margin-bottom: 2.5em;
	font-family: $ctm;
	font-size: 18px;
	font-weight: 500;
	line-height: 1.5;
	color: $color;

	@include tablets {
		font-size: 16px;
		font-weight: 400;
	}
}

.p {
	display: block;
	margin: .7em 0;
	font-family: $ctm;
	font-size: 16px;
	font-weight: 500;
	line-height: 1.5;
}

.p_big {
	font-size: 32px;
	font-weight: 400;
	line-height: 1.5;
}

// text align
.aligncenter { text-align: center; }
.alignright  { text-align: right; }
.alignleft   { text-align: left; }
.alignjustify{ text-align: justify; }